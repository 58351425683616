import React from "react";
import { log } from "../logger";

const supportedLocales = ["en-US", "en-GB", "en-AU", "es"] as const;
export type SupportedLocales = (typeof supportedLocales)[number];

export type I18nMessage = {
  [key: string]: I18nValue;
};
type I18nValue =
  | string
  | {
      0: string;
      1: string;
      other: string;
    }
  | undefined;

export type I18nLocale = {
  [L in SupportedLocales]: I18nMessage;
};

const defaultLanguage: SupportedLocales = "en-US";
let language: string = defaultLanguage;
let loadedMessages: I18nMessage = {};
let isDebug = process.env.NODE_ENV !== "production";
let debugMarker = isDebug ? "⌇" : "";

export function resolveBestSupportedLocale(clientAcceptedLanguages: string) {
  const acceptedLanguages = clientAcceptedLanguages.split(",");
  let exhaustiveListOfClientLocales = new Set<string>();
  for (let locale of acceptedLanguages) {
    if (supportedLocales.indexOf(locale) > -1) {
      return locale;
    }
    exhaustiveListOfClientLocales.add(locale);
    const [languageTag] = locale.split("-");
    if (!exhaustiveListOfClientLocales.has(languageTag)) {
      exhaustiveListOfClientLocales.add(languageTag);
    }
  }
  for (let clientLocale of exhaustiveListOfClientLocales) {
    if (supportedLocales.indexOf(clientLocale) > -1) {
      return clientLocale;
    }
    if (clientLocale === "en") {
      return defaultLanguage;
    }
  }

  return defaultLanguage;
}

export function setLanguage(lang: string, messages: I18nMessage) {
  language = lang;
  loadedMessages = messages;
}

export function T({
  id,
  plural,
  children,
}: {
  id?: string;
  plural?: {
    count: number;
  };
  children?: string | number;
}) {
  if (!children) {
    return null;
  }
  return <>{t(children, id, plural)}</>;
}

export function t(
  text: string | number,
  id?: string,
  plural?: {
    count: number;
  },
): string {
  const usingDefaultLanguage = language === defaultLanguage;
  const debugView = !usingDefaultLanguage;
  const messageKey = id && !usingDefaultLanguage ? id : text;
  const message = getTranslatedMsg(messageKey, plural);

  return (
    message ||
    (debugView ? debugMarker + messageKey + debugMarker : messageKey + "")
  );
}

function getTranslatedMsg(
  messageKey: string | number,
  plural?: { count: number },
): string | null {
  const message = loadedMessages[messageKey];

  if (plural) {
    return getPluralizedMsg(message, plural, messageKey);
  }

  if (!message) {
    return null;
  }

  if (typeof message !== "string") {
    if (isDebug) {
      log.warn(
        `message for key ${messageKey} should be string, was ${message}; returning key`,
      );
    }
    return messageKey + "";
  }

  return message;
}

function getPluralizedMsg(
  message: I18nValue,
  plural: { count: number },
  messageKey: string | number,
) {
  if (!message || typeof message === "string") {
    if (isDebug) {
      log.warn(`missing plural message for ${messageKey}`);
    }
    return null;
  }

  const countKey =
    plural.count === 0 || plural.count === 1 ? plural.count : "other";
  const pluralizedMsg = message[countKey];

  if (countKey === "other" && isDebug) {
    if (pluralizedMsg.indexOf("{count}") < 0) {
      log.warn(`Missing {count} in plural message for ${messageKey}`);
    }
  }

  return pluralizedMsg.replaceAll("{count}", plural.count + "");
}
