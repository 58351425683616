import pino from "pino";

const isNode =
  typeof process !== "undefined" &&
  process.versions != null &&
  process.versions.node != null;

const DEFAULT_NODE_LOG_LEVEL = "debug";
const DEFAULT_BROWSER_LOG_LEVEL = "info";

// ref - https://getpino.io/#/docs/help?id=mapping-pino-log-levels-to-google-cloud-logging-stackdriver-severity-levels
export const defaultPinoConf = {
  messageKey: "message",
  formatters: {
    messageKey: "message",
    level(label, number) {
      return {
        severity:
          PinoLevelToSeverityLookup[label] || PinoLevelToSeverityLookup["info"],
        level: number,
      };
    },
  },
};

const PinoLevelToSeverityLookup = {
  trace: "DEBUG",
  debug: "DEBUG",
  info: "INFO",
  warn: "WARNING",
  error: "ERROR",
  fatal: "CRITICAL",
};

const logger = pino({
  ...defaultPinoConf,
  ...{
    formatters: {
      ...defaultPinoConf.formatters,
      ...{
        bindings(bindings) {
          return {};
        },
      },
    },
    browser: {
      disabled: process.env.NODE_ENV === "production",
    },
  },
});

const logLevel = isNode
  ? process.env.LOG_LEVEL || DEFAULT_NODE_LOG_LEVEL
  : DEFAULT_BROWSER_LOG_LEVEL;

logger.setLogLevel = logLevel;

export const log = logger;
